import { formattedDate, addDaysToDate, lastDateOfMonth, diffDays } from "../../utils/date_utils";

const PAYMENT_TERMS = {
  END_OF_MONTH: "fdm",
  FORTY_FIVE_DAYS_END_OF_MONTH: "45fdm",
  THIRTY_DAYS_END_OF_MONTH: "30fdm",
};

const numberOfDaysTillDueDate = (startDate, value) => {
  if (value === PAYMENT_TERMS.END_OF_MONTH) {
    const dueDay = lastDateOfMonth(startDate.getMonth(), startDate.getFullYear());
    return diffDays(startDate, dueDay);
  } else if (value === PAYMENT_TERMS.FORTY_FIVE_DAYS_END_OF_MONTH) {
    let dueDay = addDaysToDate(startDate, 45);
    dueDay = lastDateOfMonth(dueDay.getMonth(), dueDay.getFullYear());
    return diffDays(startDate, dueDay);
  } else if (value === PAYMENT_TERMS.THIRTY_DAYS_END_OF_MONTH) {
    let dueDay = addDaysToDate(startDate, 30);
    dueDay = lastDateOfMonth(dueDay.getMonth(), dueDay.getFullYear());
    return diffDays(startDate, dueDay);
  } else {
    return value;
  }
};

const getSalesInvoiceDueDateInputValue = () => {
  if (document.getElementById("collection_payment_terms").value === "free") {
    return document.getElementById("field_payment_terms").value;
  } else {
    return document.getElementById("collection_payment_terms").value;
  }
};

const computeDueDate = (startDate, inputValue) => {
  const numberOfDays = numberOfDaysTillDueDate(startDate, inputValue);
  return addDaysToDate(startDate, parseInt(numberOfDays));
};

const updateDueDate = (inputValue) => {
  const dueDate = computeDueDate(new Date(), inputValue);
  const dueDateElement = document.getElementById("sales_form_due_date");
  if (dueDateElement) {
    dueDateElement.innerHTML = formattedDate(dueDate);
  }
};

export { updateDueDate, computeDueDate, getSalesInvoiceDueDateInputValue };
