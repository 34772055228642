export const roundTo = (number, numberOfDecimals) => {
  return (Math.round(number * Math.pow(10, numberOfDecimals)) / Math.pow(10, numberOfDecimals)).toFixed(
    numberOfDecimals
  );
};

export const roundToTwoDecimals = (number) => {
  return parseFloat(roundTo(number, 2));
};

export const isInteger = (string) => {
  return /^\d+$/.test(string);
};

export const bitsToMegabytes = (bits) => {
  return Math.round(bits / 1048576);
};

export const isSameSign = (number1, number2) => Math.sign(number1) === Math.sign(number2);

export const amountFormatted = (amount, currencySymbol) => {
  return `${amount.toLocaleString("fr")} ${currencySymbol}`;
};

export const numberToCurrency = (number, options = {}) => {
  let decimals = 2;
  if ("decimals" in options) {
    decimals = isInteger(options.decimals) ? options.decimals : 0;
  }
  const currency = options.currency || "EUR";

  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  }).format(parseFloat(number));
};

export const currencyToNumber = (amountWithCurrency) => {
  return amountWithCurrency.replace(/\s/g, "").replace(",", ".").replace("€", "");
};

export const sum = (targets) => {
  return targets.reduce((acc, target) => {
    let value = +target.value;
    if (!value) {
      value = 0;
    }
    return parseFloat(value) + acc;
  }, 0);
};

export const isAmount = (string) => {
  return /^-?\d+\.?\d*$/.test(string);
};

export const numericRange = (min, max, exact) => {
  if (isAmount(exact)) {
    return [exact];
  }

  if (isAmount(min)) {
    if (isAmount(max)) {
      return [min, max];
    } else {
      return [min, Number.MAX_SAFE_INTEGER];
    }
  } else if (isAmount(max)) {
    return [-Number.MAX_SAFE_INTEGER, max];
  }
};

export const isNumberInRange = (min, max, number) => {
  return number >= min && number <= max;
};
