export const EQUITY_CURRENT_ACCOUNT_NATURE = "equity_current_account";
export const SHAREHOLDERS_ACCOUNT_NATURE = "shareholders_account";
export const SUCCEEDED_PAYMENT_INTENT_STATUS = "succeeded";
export const PROCESSING_PAYMENT_INTENT_STATUS = "processing";
export const FRANCE_ALPHA_CODE = "FR";
export const REFUSED_DOCUMENT_STATUS = "2";
export const STANDARD_VAT_RATE = 0.2;
export const SIMPLE_FORM_INVALID_CLASS_NAME = "is-invalid";
export const BANK_TRANSACTION_MAX_FILE_SIZE = 6000000;
export const INVALID_INPUT_CLASS_NAME = "invalid";
export const PAPPERS_URL = "https://www.pappers.fr";
export const TURBO_STREAM_ACCEPT_HEADER = "text/vnd.turbo-stream.html";
export const FILE_ACCEPTED_FORMATS = ".pdf, .jpg, .jpeg, .png";
export const ZIP_ACCEPTED_FORMAT = ".zip";
export const SOCIAL_FORMS = {
  SAS: "SAS",
  SASU: "SASU",
  SARL: "SARL",
  EURL: "EURL",
  SCI: "SCI",
  EI: "EI",
  EIRL: "EIRL",
  MICRO: "Micro-entrepreneur",
};
export const APPLICATION_TIME_ZONE = window.globals.timezone;
