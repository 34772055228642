import { SOCIAL_FORMS } from "./constants";

export const isSiretValid = (siretNumber) => {
  return /^\d{14}$/.test(siretNumber);
};

export const isSirenValid = (term) => {
  return /^[\d ]+$/.test(term); // we consider that a term containing only numbers and spaces is meant to be a SIREN number
};

export const isEligibleForShareholders = (socialForm) => {
  return isSingleShareholderCompany(socialForm) || isEligibleForMultipleShareholders(socialForm);
};

export const isEligibleForMultipleShareholders = (socialForm) => {
  return !isIndividualEnterprise(socialForm) && !isSingleShareholderCompany(socialForm);
};

export const isSingleShareholderCompany = (socialForm) => [SOCIAL_FORMS.SASU, SOCIAL_FORMS.EURL].includes(socialForm);

export const isIndividualEnterprise = (socialForm) => [SOCIAL_FORMS.EI, SOCIAL_FORMS.EIRL].includes(socialForm);

export const isMicro = (socialForm) => socialForm === SOCIAL_FORMS.MICRO;

export const employerSalaryCost = (amount) => {
  return (amount * 1.4).toFixed(2);
};
