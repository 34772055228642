import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["track", "previousBtn", "nextBtn"];
  static values = { index: Number };

  connect() {
    this.indexValue = 0;
  }

  get carouselWidth() {
    return this.element.offsetWidth;
  }

  previous() {
    this.indexValue--;
    this.nextBtnTarget.classList.remove("hidden");
    this._updateTrackPosition();

    if (this.indexValue === 0) {
      this.previousBtnTarget.classList.add("hidden");
    }
  }

  next() {
    this.indexValue++;
    this.previousBtnTarget.classList.remove("hidden");
    this._updateTrackPosition();

    if (this._isLastPage()) {
      this.nextBtnTarget.classList.add("hidden");
    }
  }

  _isLastPage() {
    return this.trackTarget.offsetWidth - this.indexValue * this.carouselWidth < this.indexValue * this.carouselWidth;
  }

  _updateTrackPosition() {
    this.trackTarget.style.transform = "translateX(" + this.indexValue * -this.carouselWidth + "px)";
  }
}
