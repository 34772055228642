function formatState(state) {
  if (state.text === "Selectionner un pays" || state.text === "France") {
    return state.text;
  } else {
    const final_state = state.text.split(" ");
    final_state.splice(0, 1);
    return final_state.join(" ");
  }
}

const countrySelect2 = () => {
  const countryInput = document.querySelector(".country-select2");
  const addCustomerModal = document.getElementById("AddCustomerModal");
  const showCustomer = document.querySelector(".show-customer");
  if (countryInput) {
    // init select2 in create customer modal
    if (addCustomerModal) {
      $(addCustomerModal.querySelector(".country-select2")).select2({
        placeholder: "Selectionner un pays",
        dropdownParent: "#AddCustomerModal",
        language: {
          noResults: function () {
            return "Aucun résultat";
          },
        },
        templateSelection: formatState,
      });
    }

    // Init select 2 in show customer
    if (showCustomer) {
      $(".country-select2").select2({
        placeholder: "Selectionner un pays",
        language: {
          noResults: function () {
            return "Aucun résultat";
          },
        },
        templateSelection: formatState,
      });
    }

    // Remove invalid field class when a value is selected
    $(".country-select2").on("select2:select", function () {
      this.nextElementSibling.querySelector(".select2-selection").classList.remove("invalid-free-input-field");
      this.nextElementSibling.querySelector(".select2-selection").classList.remove("invalid");
    });

    // Set value to "" when close modal
    if (addCustomerModal) {
      document.addEventListener("click", function () {
        if (addCustomerModal.style.display === "none") {
          $(addCustomerModal.querySelector(".country-select2")).val("");
          $(addCustomerModal.querySelector(".country-select2")).trigger("change");
        }
      });
    }
  }
};

export { countrySelect2 };
