import { Controller } from "@hotwired/stimulus";
import FinalizeSubscription from "../../components/subscription/finalize_subscription";

/**
 * Actions associated to the form used to finalize a subscription within the checkout page
 */
export default class extends Controller {
  static targets = ["submitBtn", "loadingImg", "paymentErrorMsg"];

  onSubscriptionSubmit() {
    this._displayLoader();
  }

  onSubscriptionResponse(e) {
    const data = JSON.parse(e.detail[0].response);

    new FinalizeSubscription(
      data.payment_intent,
      data.callback_url,
      data.redirection_url,
      this.loadingImgTarget,
      this.submitBtnTarget,
      this.paymentErrorMsgTarget
    ).call();
  }

  _displayLoader() {
    this.submitBtnTarget.classList.add("hidden");
    this.loadingImgTarget.classList.remove("hidden");
    this.paymentErrorMsgTarget.textContent = "";
  }
}
