export default class {
  constructor() {
    this.provider = typeof analytics === "undefined" ? null : analytics;
  }

  page() {
    this.provider?.page();
  }

  track(eventName) {
    this.provider?.track(eventName);
  }

  identify(object) {
    if (object.id) {
      this.provider?.identify(object.id, object);
      return;
    }

    this.provider?.identify(object);
  }

  enabled() {
    return this.provider !== null;
  }
}
