import { initDatatable } from "./datatable_factory";
import { dataTableFunctions } from "./datatable_functions";
import { numberToCurrency } from "../utils/number_utils";
import { interpolate } from "../utils/string_utils";

const initTable = (datatableElement) => {
  const payload = {
    datatableElement: datatableElement,
    orderColumns: [[1, "desc"]],
    columns: datatableColumns(),
    setRequestData: setRequestData(),
    onComplete: onComplete(datatableElement),
  };
  initDatatable(payload);
};

const columnNames = {
  input: "input",
  date: "date",
  amount: "amount",
  info: "info",
  description: "description",
  subAccount: "sub_account",
};

const setRequestData = () => {
  return (data) => {
    const columnIndexes = dataTableFunctions().columnIndexes(data);

    dataTableFunctions().setColumnFilter(data, columnIndexes[columnNames.date], fiscalYearFilter());

    let subAccountId = null;
    const subAccountInput = document.querySelector("input[name='sub_account']:checked");
    if (subAccountInput) {
      subAccountId = subAccountInput.value;
    }
    dataTableFunctions().setColumnFilter(data, columnIndexes[columnNames.subAccount], subAccountId);
  };
};

const datatableColumns = () => {
  return [
    {
      data: columnNames.input,
      orderable: false,
      className: "select-column checkbox-element",
      render: function (data, type, row) {
        return interpolate(document.getElementById("select-item-checkbox-template"), {
          id: row.id,
        });
      },
    },
    { data: columnNames.date, orderable: true, className: "date-column" },
    {
      data: columnNames.amount,
      orderable: true,
      className: "amount-column",
      render: (data) => numberToCurrency(data / 100),
    },
    {
      data: columnNames.info,
      orderable: true,
      className: "info-column",
      render: (data, type, row) => {
        return interpolate(document.getElementById("accounting-line-info-template"), {
          description: row.info.description,
          image_path: row.info.image_path,
          type: row.info.type,
        });
      },
    },
    { data: columnNames.description, orderable: false, className: "description-column" },
    { data: columnNames.subAccount, orderable: false, visible: false },
  ];
};

const fiscalYearFilter = () => {
  return document.getElementById("filtered_fiscal_year").value;
};

const onComplete = (datatableElement) => {
  return () => {
    // unlike other input filtered the shareholders inputs are individual input for each shareholder
    // therefore we must add an event on each shareholder
    const shareholderInputArray = [...document.querySelectorAll("[name='sub_account']")].map((button) => button.id);
    const clickInputArray = [...shareholderInputArray];
    clickInputArray.forEach((input) => {
      dataTableFunctions().manageInputChange(input, datatableElement.id, "click");
    });
  };
};

export { initTable };
