import { Controller } from "@hotwired/stimulus";
import Tracker from "../../tracking/tracker";

// Main controller that handles tracking on all pages of the app
export default class extends Controller {
  static targets = ["userEmail", "userId"];

  initialize() {
    this.tracker = new Tracker();

    this.identify();

    this.tracker.page(); // without this call, tracking won't work in Hubspot as Hubspot necessitate a page or track call after an identify one
  }

  identify() {
    if (!this.tracker.enabled()) return;

    if (!this._canTrack()) return;

    this.tracker.identify(this._identifyCallParams());
  }

  _canTrack() {
    return this.hasUserEmailTarget;
  }

  _identifyCallParams() {
    const params = {
      id: this.userIdTarget.dataset.value,
      email: this.userEmailTarget.dataset.value,
    };

    return params;
  }
}
