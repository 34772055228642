export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const isStringTrue = (value) => String(value).toLowerCase() === "true";

export const isStringFalse = (value) => String(value).toLowerCase() === "false";

export const splitValuesByVariousSeparators = (value) => value.trim().split(/(?:,|;| )+/);

export const isValidEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const allEmailsValid = (value) => {
  const emails = value.replace(/\s/g, "").split(/,/);

  return emails.every((email) => isValidEmail(email));
};

export const isPasswordValid = (password) => {
  return (
    password.length >= 8 &&
    password.toUpperCase() !== password &&
    password.toLowerCase() !== password &&
    /\d/.test(password)
  );
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.substring(1);
};

export const capitalizeFullName = (firstName = "", lastName = "") => {
  const capitalizeName = (name) => {
    if (!name) {
      return "";
    }
    return name
      .split(/([ _-])/)
      .map((word) => capitalize(word))
      .join("");
  };
  return `${capitalizeName(firstName)} ${capitalizeName(lastName)}`;
};

export const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const openModal = (id) => {
  const element = $(`#${id}`);
  if (!element.modal) {
    // Fallback for the front-office, due to Bootstrap conflicts
    // TODO: resolve Bootstrap conflicts between front and back office
    window.$(`#${id}`).modal("show");
  } else {
    element.modal("show");
  }
};

export const closeModal = (id) => {
  const element = $(`#${id}`);
  if (!element.modal) {
    // Fallback for the front-office, due to Bootstrap conflicts
    // TODO: resolve Bootstrap conflicts between front and back office
    window.$(`#${id}`).modal("hide");
  } else {
    element.modal("hide");
  }
};

export const redirectTo = (path) => {
  window.location.href = `${window.location.origin}${path}`;
};

export const redirectToNewTab = (url) => {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.target = "_blank";
  anchor.click();
};

export const isInputFilled = (input) => {
  return input.value !== "";
};

export const isSwalConfirmed = (value) => {
  return value === "oui";
};

export const isControlKeyPressed = (event) => {
  return event.metaKey || event.ctrlKey;
};

export const updateTooltipTitle = (element, newTitle) => {
  window.$(element).attr("title", newTitle).tooltip("fixTitle");
};

export const escapeHtml = (str) => {
  return str.replace(
    /[&<>'"]/g,
    (tag) =>
      ({
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        "'": "&#39;",
        '"': "&quot;",
      }[tag])
  );
};

export const parseStringToHtml = (string) => {
  const parser = new DOMParser();
  return parser.parseFromString(string, "text/html");
};

export const htmlTextContent = (string) => {
  return parseStringToHtml(string).body.textContent;
};

export const scrollToField = (field, behaviour = "smooth") => {
  field.scrollIntoView({
    behavior: behaviour,
    block: "center",
    inline: "center",
  });
};

export const radioButtonLabelFor = (input) => input.nextElementSibling;

export const swalLinkFor = (element) => element.nextElementSibling;

export const toggleClass = (element, className, condition) => {
  condition ? element.classList.remove(className) : element.classList.add(className);
};

export const appendUrlSearchParams = (url, params) => {
  url = new URL(url, window.origin);
  Object.keys(params).forEach((name) => url.searchParams.set(name, params[name]));
  return url.href;
};

export const externalLink = (url, linkContent, classes = "") => {
  const a = document.createElement("a");
  linkContent = parseStringToHtml(linkContent).body.firstChild;
  a.appendChild(linkContent);
  a.target = "_blank";
  a.rel = "noopener noreferrer";
  a.classList.add(classes);
  a.href = url;
  return a;
};

export const hideElements = (elements) => {
  elements.forEach((element) => element.classList.add("hidden"));
};

export const showElements = (elements) => {
  elements.forEach((element) => element.classList.remove("hidden"));
};

export const ajaxEvent = (event) => event.type.includes("ajax:");

export const isAjaxErrorEvent = (event) => event.type === "ajax:error";

export const turboEvent = (event) => event.type.includes("turbo:");
