import { dataTableFunctions } from "./datatable_functions";
import { toggleClass } from "../utils/misc";

const DEFAULT_SCROLL_SIZE = 143;

const initDatatable = (options) => {
  const customSearch = "search" in options;
  const table = $(options.datatableElement).dataTable({
    processing: true,
    serverSide: true,
    autoWidth: false,
    lengthChange: false,
    language: {
      search: customSearch ? options.search.html : null,
      searchPlaceholder: customSearch ? options.search.placeholder : null,
      info: "_START_ - _END_ sur _TOTAL_",
      infoEmpty: "",
      infoFiltered: "",
      emptyTable: "Aucune donnée disponible",
      zeroRecords: "Aucune donnée disponible pour ces critères de recherche",
      processing: "<div id='datatables-processing'><img><p>Chargement en cours</p></div>",
    },
    ajax: {
      url: options.datatableElement.dataset.source,
      data: function (d) {
        if ("setRequestData" in options) {
          options.setRequestData(d);
        }
      },
    },
    searching: customSearch,
    order: options.orderColumns,
    scrollY: "scrollY" in options ? options.scrollY : DEFAULT_SCROLL_SIZE,
    iDisplayLength: 25,
    columns: options.columns,
    createdRow: function (row, data) {
      $(row).attr({
        class: `item-row row-${data.id}`,
        "data-datatables-target": "row",
        "data-action": "click->datatables#handleRowSelection",
        "data-id": data.id,
      });

      if ("onCreatedRow" in options) {
        options.onCreatedRow(row, data);
      }
    },
    initComplete: () => {
      const { id } = options.datatableElement;
      initNumberOfItemsSelection(id);
      if ("onComplete" in options) {
        options.onComplete();
      }
      initDatatableCustomEvents(id);
    },
    drawCallback: function (data) {
      if ("exportable" in options) {
        const { json = {} } = data;
        dataTableFunctions().setExportIds(json.exportable_record_ids);
      }

      if (dataTableFunctions().datatablesController()) {
        dataTableFunctions().datatablesController().keepSelectedRowsOnTableReload();
      }
      if ("onDraw" in options) {
        options.onDraw();
      }
    },
  });

  $(options.datatableElement).on("processing.dt", function (e, settings, processing) {
    toggleClass(document.querySelector(".dataTables_scrollBody"), "datatables-wrapper-processing", !processing);
  });

  return table;
};

const initNumberOfItemsSelection = (datatableId) => {
  const numberOfItemsSelectionInputs = ["25-results", "50-results", "100-results"];
  const event = "change";
  numberOfItemsSelectionInputs.forEach((input) => dataTableFunctions().manageInputChange(input, datatableId, event));
};

const initDatatableCustomEvents = (datatableId) => {
  document.addEventListener("datatable:reloadRows", (event) => {
    dataTableFunctions().reloadRows(datatableId, event.detail);
  });
};

export { initDatatable };
