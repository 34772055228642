import { Controller } from "@hotwired/stimulus";
import { isEnterKey } from "../utils/inputs_utils";

export default class extends Controller {
  connect() {
    this.element.addEventListener("keypress", (e) => {
      if (isEnterKey(event.keyCode)) {
        e.preventDefault();
        return;
      }
    });
  }
}
